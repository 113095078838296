var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"login_page registration4"},[_c('div',{staticClass:"page_container"},[_c('div',{staticClass:"login_inner"},[_c('div',{staticClass:"login_block"},[_c('div',{staticClass:"login_top"},[_c('Logo'),_c('LngSwitcher',{attrs:{"lngBlock":"login_lg"}})],1),_c('div',{staticClass:"login_form"},[_c('div',{staticClass:"title_row"},[_c('div',{staticClass:"form_title"},[_vm._v(" "+_vm._s(_vm.$t("Register"))+" ")]),_c('div',{staticClass:"steps_block"},[_c('div',{staticClass:"step_number"},[_vm._v(" "+_vm._s(_vm.$t("Step"))+" "+_vm._s(_vm.reg_step)+" / "),_c('span',{class:{ primary: _vm.reg_step == 3 }},[_vm._v("3")])]),_c('ul',_vm._l((3),function(count){return _c('li',{key:count},[_c('span',{class:{ selected: count == _vm.reg_step }})])}),0)])]),_c('div',{staticClass:"step-3"},[_c('div',{staticClass:"welcome_block"},[_vm._v(" "+_vm._s(_vm.$t("Welcome"))+" ")]),_c('div',{staticClass:"form_description"},[_vm._v(" "+_vm._s(_vm.$t("Enter the password"))+" ")]),_c('ValidationObserver',{ref:"set_pass_validation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"reg-step-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(" "+_vm._s(_vm.$t("Enter the new password"))+" ")]),((_vm.passwordType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"password_field pass_input",attrs:{"autocomplete":"off","name":"reg_pass","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.passwordType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"password_field pass_input",attrs:{"autocomplete":"off","name":"reg_pass","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"password_field pass_input",attrs:{"autocomplete":"off","name":"reg_pass","type":_vm.passwordType},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"error_hint"},[_c('span',{staticClass:"standard_hint"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"individual_hint"},[_vm._v("at least 8 characters")])]),_c('span',{staticClass:"icon_show show_pass",class:{ hide_pass: _vm.type === 'text' },on:{"click":function($event){_vm.passwordType === 'password'
                            ? (_vm.passwordType = 'text')
                            : (_vm.passwordType = 'password')}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(" "+_vm._s(_vm.$t("Confirm password"))+" ")]),((_vm.passwordConfirmType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"confirm_field pass_input",attrs:{"name":"pass_confirm","autocomplete":"off","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.passwordConfirm)?_vm._i(_vm.passwordConfirm,null)>-1:(_vm.passwordConfirm)},on:{"change":function($event){var $$a=_vm.passwordConfirm,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.passwordConfirm=$$a.concat([$$v]))}else{$$i>-1&&(_vm.passwordConfirm=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.passwordConfirm=$$c}}}}):((_vm.passwordConfirmType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"confirm_field pass_input",attrs:{"name":"pass_confirm","autocomplete":"off","type":"radio"},domProps:{"checked":_vm._q(_vm.passwordConfirm,null)},on:{"change":function($event){_vm.passwordConfirm=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"confirm_field pass_input",attrs:{"name":"pass_confirm","autocomplete":"off","type":_vm.passwordConfirmType},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),_c('span',{staticClass:"error_hint"},[_vm._v("passwords do not match")]),_c('span',{staticClass:"icon_show show_pass",on:{"click":function($event){_vm.passwordConfirmType === 'password'
                            ? (_vm.passwordConfirmType = 'text')
                            : (_vm.passwordConfirmType = 'password')}}})])]}}],null,true)}),_c('button',{staticClass:"validate_btn"},[_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")])],1)]}}])})],1)])]),_c('AppStore')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }